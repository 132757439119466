import Utility from "../../../shared/utility.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "./addFav.js";
import EncryptUtility from "./encrypt-utility.js";
import utilityService from "./utility-service.js";
import commonService from "../../common/js/common-api-service.js";
export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      favouriteList: [],
      pageUrl: "",
      subPage: "",
      showAdd: false,
      subPageUrl: "",
      backSlash: true,
      backSlashParent: true,    
      required: [(v) => !!v || "Field is required"],
      refresh: true,
      rulesPostive: [
        (v) => parseInt(v) > 0 || "Field should be greater than 0",
        (v) => parseInt(v) < 100 || "Field should be less than 100",
      ],
      userId: EncryptUtility.localStorageDecrypt("userID"),
      selectedProject: "",
      projectList: [],
      selectedUser: "",
      userList: [],
      userDisabled: false,
      totalRecords: 0,
      projectSelected: true,
      userSelected: false,
      auditProjectItems: [],
      auditUserItems: [],
      searchProject: "",
      searchUser: "",
      auditDialog: false,
      auditByBusiness: false,
      auditByUser: false,
      selectedBusinessAudit: "",
      auditRateBusiness: 0,
      activeStatusBusiness: false,
      selectedUserAudit: "",
      selectedBusinessAuditUser: "",
      auditRateUser: 0,
      activeStatusUser: false,
      projectListAudit: [],
      userListAudit: [],
      auditSelected: false,
      businessDetails: [],
      userDetails: [],
      businessListAuditEdit: [],
      projIncludeAll: 0,
      userIncludeAll: 0,
      isFormValid: false,
      searchDisabled: false,
      businessListAuditUser: [],
      businessListAuditEditUser: [],
      deleteAuditDialog: false,
      editAuditDetails: [],
      businessList: [],
      selectedBusiness: "",
      projSelected: true,
      businessListAudit: [],
      businessSearchJSON: [],
      userSearchJSON: [],
      selectedProjectAudit: "",
      selectedProjectAuditUser: "",
      projectListAuditUser: [],
      projectAuditSelected: false,
      projectUserAuditSelected: false,
      auditRateRules: [(v) => /(100)|(0*\d{1,2})/.test(v) || "Audit Rate has to be below 100 and greater than 0"],
      auditHeadersForProject: [
        { text: "Business", value: "business", class: "primary customwhite--text" },
        { text: "Audit Rate", value: "ooba_rate", class: "primary customwhite--text" },
        { text: "Created By", value: "Cby", class: "primary customwhite--text" },
        { text: "Created Date", value: "cdatetime", class: "primary customwhite--text" },
        { text: "Status", value: "active", class: "primary customwhite--text" },
        { text: "Actions", value: "actions", class: "primary customwhite--text" },
      ],
      auditHeadersForUser: [
        { text: "User", value: "UserName", class: "primary customwhite--text" },
        { text: "Business", value: "business", class: "primary customwhite--text" },
        { text: "Audit Rate", value: "ooba_rate", class: "primary customwhite--text" },
        { text: "Created By", value: "Cby", class: "primary customwhite--text" },
        { text: "Created Date", value: "cdatetime", class: "primary customwhite--text" },
        { text: "Status", value: "active", class: "primary customwhite--text" },
        { text: "Actions", value: "actions", class: "primary customwhite--text" },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getProjUserData();
  },
  mounted() {},
  methods: {
        // to make sure user can only add number value
        isNumber(event){
          let value= Utility.isNumber(event)
          return value
        },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    //Add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Reset Function
    resetFunction() {
      this.$refs.oobaSearchForm?.resetValidation()
      this.selectedProject = "";
      this.projectList = [];
      this.selectedUser = "";
      this.userList = [];
      this.userDisabled = false;
      this.totalRecords = 0;
      this.projectSelected = true;
      this.userSelected = false;
      this.auditProjectItems = [];
      this.auditUserItems = [];
      this.searchDisabled = false;
      this.deleteAuditDialog = false;
      this.editAuditDetails = [];
      this.businessList = [];
      this.selectedBusiness = "";
      this.projSelected = true;
      this.businessListAudit = [];
      this.businessSearchJSON = [];
      this.onCancel();
      this.getProjUserData();
    },
    //Project and User Data
    async getProjUserData() {
      let projData = await commonService.getProject("get", this.userId);
      if (projData != null && projData != undefined && projData != {}) {
        this.projectList = projData.ProjectInfo;
      }
      let obj = {
        Pass: 0,
        UserId: parseInt(this.userId),
        json: "{}",
      };
      let showMessage = false;
      let userData = await utilityService.outOfTheBoxUserRatings("post", obj, showMessage);
      if (userData != null && userData != undefined && userData != {}) {
        this.userList = userData.OBRS_Data;
       // this.userList.unshift(JSON.parse('{"UserId":-1, "Name":"ALL"}'));
      }
    },
    //Audit by Project change
    async businessAudit() {
      this.auditByUser = false;
      this.selectedUserAudit = "";
      this.selectedProjectAudit = "";
      this.selectedBusinessAuditUser = "";
      this.auditRateUser = 0;
      this.activeStatusUser = false;
      this.auditSelected = true;
      let projData = await commonService.getProject("get", this.userId);
      if (projData != null && projData != undefined && projData != {}) {
        this.projectListAudit = projData.ProjectInfo;
      }
    },
    //Audit Project selected
    async auditProjectSelected() {
      if (this.auditByBusiness) {
        this.projectAuditSelected = true;
        let businessData = await commonService.getBusinessOnProject("get", this.userId);
        if (businessData !== undefined && businessData !== null) {
          this.businessListAudit = businessData.filter((obj) => obj.ProjectId === this.selectedProjectAudit);
        }
      } else if (this.auditByUser) {
        this.projectUserAuditSelected = true;
        let businessData = await commonService.getBusinessOnProject("get", this.userId);
        if (businessData !== undefined && businessData !== null) {
          this.businessListAuditUser = businessData.filter((obj) => obj.ProjectId === this.selectedProjectAuditUser);
        }
      }
    },
    //Audit by User change
    async userAudit() {
      this.auditByBusiness = false;
      this.selectedBusinessAudit = "";
      this.auditRateBusiness = 0;
      this.activeStatusBusiness = false;
      this.auditSelected = true;
      this.selectedProjectAuditUser = "";
      let projData = await commonService.getProject("get", this.userId);
      if (projData != null && projData != undefined && projData != {}) {
        this.projectListAuditUser = projData.ProjectInfo;
      }
      let objUser = {
        Pass: 0,
        UserId: parseInt(this.userId),
        json: "{}",
      };
      let messageShow = false;
      let userData = await utilityService.outOfTheBoxUserRatings("post", objUser, messageShow);
      if (userData != {} && userData != undefined && userData != null) {
        this.userListAudit = userData.OBRS_Data;
      }
    },
    //New Item click event
    newItemClicked() {
      this.auditDialog = false;
      this.auditByBusiness = false;
      this.auditByUser = false;
      this.selectedBusinessAudit = "";
      this.selectedProjectAudit = "";
      this.auditRateBusiness = 0;
      this.activeStatusBusiness = false;
      this.selectedUserAudit = "";
      this.selectedBusinessAuditUser = "";
      this.auditRateUser = 0;
      this.activeStatusUser = false;
      this.businessListAuditEdit = [];
      this.userListAudit = [];
      this.auditSelected = false;
      this.auditDialog = true;
    },
    //Cancel and close button event
    onCancel() {
      if (this.editAuditDialog) {
        this.businessDetails = [];
        this.userDetails = [];
        this.auditByBusiness = false;
        this.auditByUser = false;
        this.editAuditDialog = false;
        this.selectedBusinessAudit = "";
        this.auditRateBusiness = 0;
        this.activeStatusBusiness = false;
        this.selectedUserAudit = "";
        this.selectedBusinessAuditUser = "";
        this.auditRateUser = 0;
        this.activeStatusUser = false;
        this.businessListAuditEdit = [];
        this.userListAudit = [];
        this.auditSelected = false;
      } else if (this.auditDialog) {
        this.auditDialog = false;
        this.auditByBusiness = false;
        this.auditByUser = false;
        this.selectedBusinessAudit = "";
        this.auditRateBusiness = 0;
        this.activeStatusBusiness = false;
        this.selectedUserAudit = "";
        this.selectedBusinessAuditUser = "";
        this.auditRateUser = 0;
        this.activeStatusUser = false;
        this.businessListAuditEdit = [];
        this.userListAudit = [];
        this.auditSelected = false;
        this.projectAuditSelected = false;
        this.projectUserAuditSelected = false;
        if (this.auditByBusiness) {
          this.$refs.businessAuditForm.resetValidation();
        } else if (this.auditByUser) {
          this.$refs.userAuditForm.resetValidation();
        }
      } else if (this.deleteAuditDialog) {
        this.deleteAuditDialog = false;
      }
    },
    //Audit Rate of Project
    changeAuditRateBusiness() {
      if (this.auditRateBusiness <= 0) {
        this.activeStatusBusiness = false;
      } else {
        this.activeStatusBusiness = true;
      }
    },
    //Audit Rate of User
    changeAuditRateUser() {
      if (this.auditRateUser <= 0) {
        this.activeStatusUser = false;
      } else {
        this.activeStatusUser = true;
      }
    },
    //Project Change
    async projectChange() {
      this.selectedUser = "";
      this.userSelected = false;
      this.searchDisabled = false;
      this.projSelected = false;
      let businessData = await commonService.getBusinessOnProject("get", this.userId);
      if (businessData !== undefined && businessData !== null) {
        this.businessList = businessData.filter((obj) => obj.ProjectId === this.selectedProject);
        this.businessList.unshift(JSON.parse('{"BusinessId":-1, "Business":"ALL"}'));
      }
    },
    //User Change
    userChange() {
      this.selectedProject = "";
      this.selectedBusiness = "";
      this.projectSelected = false;
      this.searchDisabled = false;
      this.projSelected = true;
    },
    //Search Cancel
    onSearchCancel() {
      
      this.$refs.oobaSearchForm?.resetValidation()
      this.selectedProject = "";
      this.selectedUser = "";
      this.projectSelected = true;
      this.userSelected = false;
      this.totalRecords = 0;
      this.searchDisabled = false;
      this.projSelected = true;
      this.selectedBusiness = "";
      this.auditProjectItems = [];
      this.auditUserItems = [];
    },
    //Project Actions button click event
    async editBusinessAudit(item) {
      this.editAuditDetails = [];
      this.editAuditDetails = item;
      this.businessDetails = [];
      this.businessDetails = item;
      let businessData = await commonService.getBusinessOnProject("get", this.userId);
      if (businessData !== null && businessData !== undefined) {
        this.businessListAuditEdit = businessData;
      }
      this.auditByBusiness = true;
      this.selectedBusinessAudit = this.editAuditDetails.bu_key;
      this.auditRateBusiness = this.editAuditDetails.ooba_rate;
      this.activeStatusBusiness = this.editAuditDetails.active;
      this.editAuditDialog = true;
      this.auditSelected = true;
    },
    //User Actions button click event
    async editUserAudit(item) {
      this.editAuditDetails = [];
      this.editAuditDetails = item;
      this.userDetails = [];
      this.userDetails = item;
      let objUser = {
        Pass: 0,
        UserId: parseInt(this.userId),
        json: "{}",
      };
      let messageShow = false;
      let userData = await utilityService.outOfTheBoxUserRatings("post", objUser, messageShow);
      if (userData != {} && userData != undefined && userData != null) {
        this.userListAudit = userData.OBRS_Data;
      }
      let businessData = await commonService.getBusinessOnProject("get", this.userId);
      if (businessData !== null && businessData !== undefined) {
        this.businessListAuditEditUser = businessData;
      }
      this.auditByUser = true;
      this.selectedUserAudit = this.editAuditDetails.user_key;
      this.selectedBusinessAuditUser = this.editAuditDetails.bu_key;
      this.auditRateUser = this.editAuditDetails.ooba_rate;
      this.activeStatusUser = this.editAuditDetails.active;
      this.editAuditDialog = true;
      this.auditSelected = true;
    },
    //Search button click event
    async onSearchClick(state) {
      if(this.$refs.oobaSearchForm?.validate()){
      if (this.selectedBusiness != "") {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.businessSearchJSON = [];
        if (this.selectedBusiness == -1) {
          this.projIncludeAll = 1;
          this.businessSearchJSON = JSON.stringify({
            all_records: this.projIncludeAll,
          });
        } else {
          this.projIncludeAll = 0;
          let business = [this.selectedBusiness];
          this.businessSearchJSON = JSON.stringify({
            bu_key: business,
          });
        }
        let obj = {
          Pass: 1,
          UserId: parseInt(this.userId),
          json: this.businessSearchJSON,
        };
        this.axios
          .post("co/ooba_business_rating", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let data = JSON.parse(response.data.body.message);
              const newArr = data.OBRS_List.map((obj) => {
                return {
                  ...obj,
                  cdatetime: Utility.convertESTToLocal(obj.cdatetime),
                };
              });
              this.auditProjectItems = newArr;
              this.totalRecords = newArr.length;
              this.projectSelected = true;
              this.searchDisabled = false;
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            this.projectSelected = true;
            this.userSelected = false;
            this.auditProjectItems = [];
            this.auditUserItems = [];
            this.totalRecords = 0;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            if (state !== "delete") {
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            this.errorMessage = error.message;
          });
      } else if (this.selectedUser != "") {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.userSearchJSON = [];
        if (this.selectedUser.includes(-1)) {
          this.userIncludeAll = 1;
          this.userSearchJSON = JSON.stringify({
            all_records: this.userIncludeAll,
          });
        } else {
          this.userIncludeAll = 0;
          this.userSearchJSON = JSON.stringify({
            user_key: this.selectedUser,
          });
        }
        let obj = {
          Pass: 1,
          UserId: parseInt(this.userId),
          json: this.userSearchJSON,
        };
        this.axios
          .post("co/ooba_user_rating", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let data = JSON.parse(response.data.body.message);
              const newArr = data.OBRS_List.map((obj) => {
                return {
                  ...obj,
                  cdatetime: Utility.convertESTToLocal(obj.cdatetime),
                };
              });
              this.auditUserItems = newArr;
              this.totalRecords = newArr.length;
              this.userSelected = true;
              this.projectSelected = false;
              this.searchDisabled = true;
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            this.projectSelected = true;
            this.userSelected = false;
            this.auditProjectItems = [];
            this.auditUserItems = [];
            this.totalRecords = 0;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            if (state !== "delete") {
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            this.errorMessage = error.message;
          });
      }

    }

    },
    //Refreshing the Audit Dialog
    refreshAuditDialog() {
      if (this.auditDialog) {
        this.auditByBusiness = false;
        this.auditByUser = false;
        this.selectedBusinessAudit = "";
        this.auditRateBusiness = 0;
        this.activeStatusBusiness = false;
        this.selectedUserAudit = "";
        this.selectedBusinessAuditUser = "";
        this.auditRateUser = 0;
        this.activeStatusUser = false;
        this.auditSelected = false;
        this.projectAuditSelected = false;
        this.projectUserAuditSelected = false;
        this.selectedProjectAudit = "";
        this.selectedProjectAuditUser = "";
      }
    },
    //Add button in New item
    clickAddAudit() {
      if (this.auditByUser) {
        if (this.$refs.userAuditForm.validate()) {
          let LoaderDialog = {
            visible: true,
            title: "Please Wait...",
          };
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let user = [this.selectedUserAudit];
          let searchJson = JSON.stringify({
            pooba_id: "1",
            ooba_rate: this.auditRateUser,
            user_key: user,
            bu_key: this.selectedBusinessAuditUser,
            active: this.activeStatusUser == true ? 1 : 0,
          });
          let obj = {
            Pass: 2,
            UserId: parseInt(this.userId),
            json: searchJson,
          };
          this.axios
            .post("co/ooba_user_rating", obj)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                this.onCancel();
                this.onSearchClick();
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "success",
                  isShow: true,
                  message: response.data.body.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            });
        }
      } else if (this.auditByBusiness) {
        if (this.$refs.businessAuditForm.validate()) {
          let LoaderDialog = {
            visible: true,
            title: "Please Wait...",
          };
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let business = [this.selectedBusinessAudit];
          let searchJson = JSON.stringify({
            pooba_id: "1",
            ooba_rate: this.auditRateBusiness,
            bu_key: business,
            active: this.activeStatusBusiness == true ? 1 : 0,
          });
          let obj = {
            Pass: 2,
            UserId: parseInt(this.userId),
            json: searchJson,
          };
          this.axios
            .post("co/ooba_business_rating", obj)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                this.onCancel();
                this.onSearchClick();
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "success",
                  isShow: true,
                  message: response.data.body.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            });
        }
      }
    },
    //Update button in Edit popup
    clickUpdateAudit() {
      if (this.auditByBusiness) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let searchJson = JSON.stringify({
          pooba_id: this.businessDetails.pooba_id,
          ooba_rate: this.auditRateBusiness,
          active: this.activeStatusBusiness == true ? 1 : 0,
        });
        let obj = {
          Pass: 3,
          UserId: parseInt(this.userId),
          json: searchJson,
        };
        this.axios
          .post("co/ooba_business_rating", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              this.onCancel();
              this.onSearchClick();
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          });
      } else if (this.auditByUser) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let searchJson = JSON.stringify({
          pooba_id: this.userDetails.pooba_id,
          ooba_rate: this.auditRateUser,
          active: this.activeStatusUser == true ? 1 : 0,
        });
        let obj = {
          Pass: 3,
          UserId: parseInt(this.userId),
          json: searchJson,
        };
        this.axios
          .post("co/ooba_user_rating", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              this.onCancel();
              this.onSearchClick();
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          });
      }
    },
    //Delete button click event
    auditDelete() {
      if (this.auditByBusiness) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let searchJson = JSON.stringify({
          pooba_id: this.editAuditDetails.pooba_id,
        });
        let obj = {
          Pass: 4,
          UserId: parseInt(this.userId),
          json: searchJson,
        };
        this.axios
          .post("co/ooba_business_rating", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              this.deleteAuditDialog = false;
              this.editAuditDetails = [];
              this.editAuditDialog = false;
              this.onSearchClick("delete");
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          });
      } else if (this.auditByUser) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let searchJson = JSON.stringify({
          pooba_id: this.editAuditDetails.pooba_id,
        });
        let obj = {
          Pass: 4,
          UserId: parseInt(this.userId),
          json: searchJson,
        };
        this.axios
          .post("co/ooba_user_rating", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              this.deleteAuditDialog = false;
              this.editAuditDetails = [];
              this.editAuditDialog = false;
              this.onSearchClick("delete");
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          });
      }
    },
  },
  components: {
    breadcrumbComp,
  },
};
